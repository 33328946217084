<template>
  <div class="create-posts">
    <el-row type="flex" align="middle" class="page-title">
      <i class="el-icon-menu mr-s" />
      <h3>Thêm bài viết mới</h3>
    </el-row>
    <el-row>
      <el-col :span="24" :lg="16">
        <el-card v-loading="loadingData || loadingCategory">
          <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="Tên bài viết" prop="name">
              <el-input v-model="form.name" placeholder="Tên bài viết" />
            </el-form-item>
            <!-- <el-form-item label="Tên tác giả">
              <el-input v-model="form.author" placeholder="Tên tác giả" />
            </el-form-item> -->
            <el-form-item label="Mô tả">
              <el-input
                type="textarea"
                :rows="3"
                v-model="form.description"
                placeholder="Mô tả"
              />
            </el-form-item>
            <el-form-item label="Chọn danh mục" prop="category">
              <el-select
                v-model="form.category"
                :loading="loadingCategory"
                placeholder="Chọn danh mục"
                filterable
                class="w-full"
              >
                <el-option
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="Ngày xuất bản bài viết" prop="publishedAt">
              <el-date-picker
                v-model="form.publishedAt"
                class="w-full"
                type="date"
                placeholder="Chọn ngày xuất bản"
              />
            </el-form-item>
            <el-form-item label="Tải lên ảnh đại diện bài viết">
              <el-row type="flex" class="w-full">
                <el-upload
                  ref="uploadAvatar"
                  class="upload-avatar"
                  drag
                  accept=".jpeg,.png,.jpg,.gif"
                  action="#"
                  :auto-upload="false"
                  :on-change="changeAvatar"
                  :show-file-list="false"
                >
                  <div class="w-full">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      Kéo thả ảnh hoặc <em>click để upload ảnh</em>
                    </div>
                  </div>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-row
              v-show="isUploadedAvatar"
              class="preview-avatar"
              type="flex"
              justify="center"
            >
              <img id="avatar-img-url" />
              <p v-if="fileAvatar">{{ fileAvatar ? fileAvatar.name : "" }}</p>
            </el-row>

            <el-form-item label="Tải lên bài viết">
              <el-row type="flex" class="w-full">
                <el-upload
                  class="upload-posts"
                  action="#"
                  accept=".md"
                  :auto-upload="false"
                  :on-change="changePosts"
                  :show-file-list="false"
                >
                  <el-button size="small" type="primary">
                    Click upload
                  </el-button>
                  <div slot="tip" class="el-upload__tip">
                    Chấp nhận các file .md
                  </div>
                </el-upload>
              </el-row>
              <el-button
                type="text"
                v-if="previewContentHtml"
                @click="visiblePreview = true"
              >
                {{ fileContent ? fileContent.name : "Preview bài viết" }}
              </el-button>
            </el-form-item>
            <el-form-item label="SEO title">
              <el-input
                v-model="form.metaTitle"
                :disabled="callingAPI"
                placeholder="SEO title"
              />
            </el-form-item>
            <el-form-item label="SEO keyword">
              <el-input
                v-model="form.metaKeyword"
                :disabled="callingAPI"
                placeholder="SEO keyword"
              />
            </el-form-item>
            <el-form-item label="SEO description">
              <el-input
                v-model="form.metaDescription"
                :disabled="callingAPI"
                placeholder="SEO description"
              />
            </el-form-item>
          </el-form>
          <el-row type="flex" justify="end">
            <el-button
              class="mt-xl"
              type="primary"
              :loading="callingAPI"
              @click="handleUpdatePosts"
            >
              Cập nhật bài viêt
            </el-button>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      :visible="visiblePreview"
      width="900px"
      top="85px"
      @close="visiblePreview = false"
    >
      <div
        v-if="previewContentHtml"
        class="preview-file"
        v-html="previewContentHtml"
      ></div>
    </el-dialog>
  </div>
</template>

<script>
import { fetchCategory } from "@/services/category";
import { upload } from "@/services/storage";
import { fetchPostsById, updatePosts } from "@/services/posts";
import { markdown } from "markdown";
import { convertDate } from "@/utils/filters";
const slugify = require("slugify");
export default {
  name: "UpdatePosts",
  data() {
    return {
      loadingData: false,
      callingAPI: false,
      loadingCategory: false,
      visiblePreview: false,

      isUploadedAvatar: false,

      form: {
        name: "",
        author: "",
        description: "",
        publishedAt: "",
        category: "",
        metaTitle: "",
        metaKeyword: "",
        metaDescription: "",
        postsContent: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Tên bài viết không được để trống",
            trigger: ["blur", "change"],
          },
        ],
        category: [
          {
            required: true,
            message: "Danh mục bài viết không được để trống",
            trigger: ["blur", "change"],
          },
        ],
        publishAt: [
          {
            required: true,
            message: "Ngày xuất bản bài viết không được để trống",
            trigger: ["blur", "change"],
          },
        ],
      },
      photo_url_path: "",
      photo_url_path_upload: "",
      categories: [],

      fileAvatar: null,
      fileContent: null,
      previewContentHtml: null,
    };
  },
  async beforeMount() {
    await this.loadCategory();
    await this.loadData();
  },
  methods: {
    async loadCategory() {
      try {
        this.loadingCategory = true;
        const response = await fetchCategory();
        this.categories = response.data;
        this.loadingCategory = false;
      } catch (error) {
        this.loadingCategory = false;
      }
    },

    async loadData() {
      try {
        this.loadingData = true;
        const response = await fetchPostsById(this.$route.params.id);
        this.form.name = response.data.name;
        this.form.description = response.data.description;
        this.form.category = response.data.category_id;
        this.form.publishedAt = response.data.published_at;
        this.form.metaTitle = response.data.meta_title;
        this.form.metaDescription = response.data.meta_description;
        this.form.metaKeyword = response.data.meta_keyword;
        this.form.postsContent = response.data.content;
        this.previewContentHtml = markdown.toHTML(response.data.content);
        this.isUploadedAvatar = true;
        this.photo_url_path = response.data.photo_url_path;
        this.photo_url_path_upload = response.data.photo_url_path;
        var output = document.getElementById("avatar-img-url");
        output.src = response.data.photo_url;
        this.loadingData = false;
      } catch (error) {
        this.loadingData = false;
        console.log(error);
      }
    },

    changeAvatar(file) {
      this.photo_url_path_upload = file.name;
      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById("avatar-img-url");
        output.src = reader.result;
      };
      this.isUploadedAvatar = true;
      reader.readAsDataURL(file.raw);
      this.fileAvatar = file;
    },

    changePosts(file) {
      const reader = new FileReader();
      reader.onload = (res) => {
        this.form.postsContent = res.target.result;
        this.previewContentHtml = markdown.toHTML(res.target.result);
      };
      reader.readAsText(file.raw);
      this.fileContent = file;
    },

    async handleUpdatePosts() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!this.photo_url_path_upload) {
            this.$message.error("Vui lòng tải lên ảnh đại diện cho bài viết.");
            return;
          }
          if (!this.form.postsContent) {
            this.$message.error("Vui lòng tải lên nội dung bài viết.");
            return;
          }

          try {
            this.callingAPI = true;
            const data = {
              name: this.form.name,
              slug: slugify(this.form.name),
              description: this.form.description,
              content: this.form.postsContent,
              meta_title: this.form.metaTitle,
              meta_keyword: this.form.metaKeyword,
              meta_description: this.form.metaDescription,
              category_id: this.form.category,
              published_at: convertDate(this.form.publishedAt, "DD/MM/YYYY"),
            };
            if (this.fileAvatar) {
              var formData = new FormData();
              formData.append("upload", this.fileAvatar.raw);
              formData.append("type", "posts");
              const response = await upload(formData);
              data.photo_url_path = response.data[0].thumb_path;
            } else {
              data.photo_url_path = this.photo_url_path;
            }

            await updatePosts(this.$route.params.id, data);
            this.callingAPI = false;
            this.$notify.success({
              title: "Thành công",
              message: "Cập nhật bài viết thành công.",
              duration: 2000,
            });
            this.$router.push({ name: "Posts" });
          } catch (error) {
            console.log(error);
            this.callingAPI = false;
            this.$notify.error({
              title: "Thất bại",
              message: "Cập nhật bài viết thất bại.",
              duration: 2000,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.upload-avatar {
  width: 100%;
  .el-upload {
    width: 100%;
    height: 100%;

    .el-upload-dragger {
      width: 100%;
      height: 100%;
    }
  }
}
.preview-avatar {
  flex-direction: column;
  align-items: center;
  img {
    max-width: 300px;
    object-fit: cover;
  }
}
.el-upload__tip {
  line-height: 1;
}
.preview-file {
  overflow: hidden;
  img {
    max-width: 100%;
    object-fit: cover;
  }
}
</style>
